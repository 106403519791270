import type { PropsWithChildren } from "react";
import React from "react";
import Link from "~/components/link";

export type BrandProps = {
  to: string;
  external?: boolean;
  label: React.ReactNode;
};
interface Props extends PropsWithChildren<unknown> {
  rightMenu?: React.ReactNode;
  brand?: BrandProps;
  id?: string;
  rightId?: string;
  clientAccess?: boolean;
  secondary?: boolean;
}

const NavBar: React.FC<Props> = ({
  rightMenu,
  clientAccess,
  secondary,
  id,
  rightId = "right-menu-portal",
  brand,
  children
}) => (
  <nav
    className={`navbar ${
      secondary
        ? "navbar-default sub-navbar"
        : clientAccess
          ? "navbar-default primary-navbar"
          : "navbar-inverse"
    } navbar-static-top`}
    style={
      secondary && clientAccess ? { margin: "-15px -25px 10px" } : undefined
    }
  >
    {brand && (
      <div className="navbar-header">
        <Link className="navbar-brand" to={brand.to} external={brand.external}>
          {brand.label}
        </Link>
      </div>
    )}
    <div className="navbar-collapse collapse" id={id}>
      <ul className="nav navbar-nav">{children}</ul>
      <ul
        className="nav navbar-nav navbar-right"
        id={secondary ? rightId || "right-menu-portal" : undefined}
      >
        {rightMenu}
      </ul>
    </div>
  </nav>
);

export default NavBar;
