import type { LinkProps } from "~/components/link";
import Link, { useMatch } from "~/components/link";

interface Props extends LinkProps {
  exact?: boolean;
}

export default function NavLink({ children, exact, to, ...rest }: Props) {
  const match = useMatch(undefined, to, exact);

  return (
    <li className={!match ? "active" : ""}>
      <Link {...rest} to={to}>
        {children}
      </Link>
    </li>
  );
}
